/* Add this custom CSS to your stylesheets or tailwind.config.css file if needed */
.max-h-200px {
    max-height: 250px;
}
  
.line-clamp-12 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 12;
}
