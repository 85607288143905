.MuiSlider-markLabel  {
  color: white;
}

/* @import url('https://fonts.googleapis.com/css2?family=B612+Mono&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Chivo+Mono:wght@500&display=swap');

.font-custom-mono {
  font-family: 'Chivo Mono', monospace;
}

/* .show-more-button {
  display: none;
}

.show-more-visible {
  display: inline-block;
} */