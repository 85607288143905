/* YourComponentStyles.css */
.scrollable-content {
    direction: rtl;                 /* Sets the direction to right-to-left */
}
  
.scrollable-content > * {
    direction: ltr;                 /* Reverts the direction for children to left-to-right */
}

.feedback {
    position: fixed; 
    bottom: 50px; 
    transform: rotate(270deg); 
    right: -33px; margin: 0px; 
    /* z-index: 2000; */
}

.react-tooltip {
    color: #222;
    background-color: rgba(241, 243, 249, 0.9);
}
/* Dark Theme styles */
.dark .react-tooltip {
    color: #ffffff;
    background-color: rgba(45, 44, 44, 0.8);
    /* background-color: rgba(28, 131, 131, 0.8); */
}

#calendar-tooltip {
    font-size:13px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 5px; /* Adjust as needed */
}

.dark .restricted {
    background: repeating-linear-gradient(
        -55deg,
        #222,
        #222 10px,
        #333 10px,
        #333 20px
    );
}
.restricted {
    background: repeating-linear-gradient(
        -55deg,
        #ebebeb,
        #ebebeb 10px,
        #e1e1e1 10px,
        #e1e1e1 20px
    );
}