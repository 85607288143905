 /* .custom-link{
  stroke: #000000 !important;
}

@media (prefers-color-scheme: dark) {
  .custom-link{
    stroke: #ffffff !important;
  }
}  */

.cytoscape-graph .clickable-node {
  cursor: pointer !important;
}